// Diccionario que mapea el externalId de una región a un array de externalId de marcas de vehículos.
// La clave (key) representa el externalId de una región.
// El valor (value) es un array que contiene los externalId de las marcas de vehículos disponibles en esa región.

// IMPORTANTE: SI SE AGREGA UNA NUEVA MARCA SALFA DEBE ENVIAR A QUE REGION Y TALLER ESTA ASOCIADA DICHA MARCA

export const vehicleBrandsByRegion = {
  '01': [101, 171, 161],
  '02': [101, 161, 121, 484, 473, 472, 261, 181, 191, 141, 131, 151, 486],
  '03': [101],
  '04': [101],
  '013': [101],
  '08': [101],
  '09': [171],
  //'015': [171, 161, 181],
}

// Diccionario que mapea el externalId de un taller a un array de externalId de marcas de vehículos.
// La clave (key) representa el externalId de un taller mecánico.
// El valor (value) es un array que contiene los externalId de las marcas de vehículos que el taller puede atender.

export const vehicleBrandsByWorkshop = {
  'TALLER_CA01_01': [101],
  'TALLER_LS01_01': [101],
  'TALLER_SA01_01': [101],
  'TALLER_IQ01_01': [101],
  'TALLER_AN01_03': [101],
  'TALLER_CP01_01': [101],
  'TALLER_IQ01_02': [161],
  'TALLER_CA01_02': [161],
  'TALLER_CA01_03': [121, 484, 473, 472, 261, 181, 191, 141, 131, 151, 486 ],
  'TALLER_AN01_02': [121, 484, 473, 472, 261, 181, 191, 141, 131, 151, 486 ],
  'TALLER_AN01_05': [161],
  'TALLER_AR01_03': [181],
  'TALLER_AR01_01': [171],
  'TALLER_IQ01_03': [171],
  'TALLER_TE01_01': [171],
  'TALLER_CC01_01': [101],
  'TALLER_AR01_02': [161],
}

export const getRegionIdsByVehicleBrandId = (brandId) => (
  Object.entries(vehicleBrandsByRegion)
      .filter(([regionId, brandIds]) => brandIds.includes(parseInt(brandId)))
      .map(([region]) => region)
);

export const getWorkshopIdsByVehicleBrandId = (brandId) => {
  return Object.entries(vehicleBrandsByWorkshop)
      .filter(([workshopId, brandIds]) => brandIds.includes(parseInt(brandId)))
      .map(([workshop]) => workshop);
};
